.dateAndTimeWrapper {
  height: 40px;

  .firstRaw {
    font-family: lato-heavy;
    font-size: 12px;
    line-height: 13px;
    text-align: left;
    color: #444444;
  }

  .secondRaw {
    font-family: lato-medium;
    font-size: 12px;
    line-height: 13px;
    text-align: left;
    color: #444444;
  }
}
