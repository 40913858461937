@import '../../../styles/variables';

.wrapper {
  height: 70px;

  .text {
    height: 32px;
    background: #333333;
  }

  .circle {
    background-color: #92d63d;
    border-radius: 50%;
  }

  .svgIcon {
    background-color: #92d63d;
    border: 3px solid #92d63d;
    fill: #333333;
    border-radius: 100%;

    svg {
      border-radius: 100%;
    }
  }
}
