.registerForm {
  height: 300px;

  input {
    height: 40px;
  }
  input[type='text'],
  input[type='password'] {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    font-family: lato-medium;
    font-size: 22px;
  }

  label {
    color: gray;
    letter-spacing: -0px;
    font-family: lato-medium;
    font-size: 15px;
  }

  svg {
    color: #cccbc8;
  }

  .registerButton {
    height: 45px;
    background: #414143;
  }

  .error {
    font-size: 10px;
    color: red;
  }
}
