body.lock-position {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.BannerModal {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.BannerModalImage {
  height: 100%;
  width: 100%;
}

.NastaviButton {
  position: absolute;
  top: 72%;
  left: 50%;
  outline: none;
  border: none;
  background-color: rgb(24, 114, 64);
  color: white;
  width: 200px;
  height: 50px;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: bold;

  -moz-transition: background-color 0.125s ease-in;
  -o-transition: background-color 0.125s ease-in;
  -webkit-transition: background-color 0.125s ease-in;
  transition: background-color 0.125s ease-in;
}

.NastaviButton:hover {
  background-color: rgb(36, 155, 84);
}

.CloseContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 60;
  height: 60;
  cursor: pointer;
}

.HideBanner {
  visibility: hidden;
  animation: fadeOut 0.4s linear forwards;
}

.ShowBanner {
  visibility: visible;
  animation: fadeIn 0.4s linear forwards;
}

.DisplayNone {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
    visibility: hidden;
    transform: translateY(-130px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  to {
    opacity: 0.5;
    visibility: hidden;
    transform: translateY(-130px);
  }
}
