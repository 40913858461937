.headerBar {
  max-width: 1080px;
  height: 40px;
}

.headerBarSimple {
  height: 52px;
}

.continueButton {
  font-size: 1rem;
  font-family: 'lato-bold';
  min-width: 95px;
  margin-right: 10px;
  padding-top: 0;
  cursor: pointer;
}

.continueButtonSmall {
  font-size: 0.85rem !important;
}

.cursorPointer {
  cursor: pointer;
}

@media only screen and (min-width: 1366px) {
  .headerBar {
    min-width: 1300px;
  }
}
