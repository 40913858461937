@import '../../../styles/variables';

.searchBar {
  background: #c1272d;

  .searchBarForm {
    max-width: 1080px;

    .closeIcon {
      color: lightgray;
    }

    .input {
      color: lightgray;
      background: transparent;
    }

    .searchIcon {
      stroke: $text-gold;
    }
  }

  .searchBarElementsWrapper {
    border-bottom: 3px solid $text-gold;
    height: 90%;
  }
}
