.navBar {
  .navBarWrapper {
    background: #e6e6e6;

    .navBar {
      max-width: 1080px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .navBar {
    .navBarWrapper {
      overflow-x: scroll;
    }
  }
}
