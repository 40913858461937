.weather {
  transform: translateY(-50%);

  .icon {
    width: 50px;
  }

  .divider {
    color: lightgray;
  }

  .vrijeme {
    font-family: lato-medium;
    font-size: 12px;
    line-height: 13px;
    text-align: left;
    color: #444444;
  }

  .podgorica {
    font-family: lato-heavy;
    font-size: 12px;
    line-height: 13px;
    text-align: left;
    color: #444444;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 900px) {
  .weather {
  }
}
