@import '../../../styles/variables';

.navItem {
  a {
    font-size: 16px;
    border-bottom: 3px solid transparent;
    font-family: verdana-regular;
    letter-spacing: -1px;
  }
}

.navItem:hover {
  background: #cccccc;
}

.active {
  background: #cccccc;
}

@media only screen and (min-width: 900px) {
  .navItem {
    a {
      font-size: 16px;
    }
  }
}
