.dnevnePlusWrapper {
  height: 70px;

  .dnevne {
    height: 32px;
    background: #333333;
  }

  .circle {
    background-color: #fed100;
    border-radius: 50%;

    p {
      color: #333333;
    }
  }
}
