.navBar {
  .navBarWrapper {
    background: #c1272d;

    .navBar {
      max-width: 1080px;
      text-transform: uppercase;

      .dropDown {
        background: #c1272d;
        width: 150px;
      }

      .scrollbar {
        /* width */
        :root {
          --code-color: darkred;
          --code-bg-color: #aaaaaa;
          --code-font-size: 14px;
          --code-line-height: 1.4;
          --scroll-bar-color: #c5c5c5;
          --scroll-bar-bg-color: #f6f6f6;
        }

        pre {
          color: var(--code-color);
          font-size: var(--code-font-size);
          line-height: var(--code-line-height);
          background-color: var(--code-bg-color);
        }

        .code-block {
          max-height: 100px;
          overflow: auto;
          padding: 8px 7px 5px 15px;
          margin: 0px 0px 0px 0px;
          border-radius: 7px;
        }

        ::-webkit-scrollbar-corner {
          background: rgba(0, 0, 0, 0.5);
        }

        * {
          scrollbar-width: thin;
          scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
        }

        /* Works on Chrome, Edge, and Safari */
        *::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          background-color: lightgray;
        }

        *::-webkit-scrollbar-track {
          scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
        }

        *::-webkit-scrollbar-thumb {
          background-color: var(--scroll-bar-color);
          border-radius: 20px;
          border: 3px solid var(--scroll-bar-bg-color);
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .navBar {
    background: #c1272d;

    .navBarWrapper {
      overflow-x: scroll;
    }
    .search {
    }
  }
}

@media only screen and (min-width: 1366px) {
  .navBar {
    .navBarWrapper {
      .navBar {
        max-width: 1300px;
      }
    }
  }
}
