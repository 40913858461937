.loggedUserMenuModal {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  .content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    border: 1px solid #888;
  }

  /* The Close Button */
  .close {
    color: #aaa;
    font-size: 28px;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .svgIcon {
    background-color: #92d63d;
    border: 3px solid #92d63d;
    fill: #333333;
    border-radius: 100%;

    svg {
      border-radius: 100%;
    }
  }
}
