@tailwind base;
@tailwind components;

@font-face {
  font-family: lato-semibold;
  src: url('/fonts/customFonts/lato/Lato-Semibold.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: lato-heavy;
  src: url('/fonts/customFonts/lato/Lato-Heavy.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: lato-medium;
  src: url('/fonts/customFonts/lato/Lato-Medium.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: lato-bold;
  src: url('/fonts/customFonts/lato/Lato-Bold.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: lato-black;
  src: url('/fonts/customFonts/lato/Lato-Black.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: libre-caslon-bold;
  src: url('/fonts/customFonts/libreCalson/LibreCaslonBold.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: verdana-bold;
  src: url('/fonts/customFonts/verdana/verdana-bold.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: verdana-regular;
  src: url('/fonts/customFonts/verdana/verdana-regular.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std Bold';
  src: url('https://db.onlinewebfonts.com/t/961a181da27e7cbc072ec2fb5bbfe2a9.eot'); /* IE9*/
  src: url('https://db.onlinewebfonts.com/t/961a181da27e7cbc072ec2fb5bbfe2a9.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://db.onlinewebfonts.com/t/961a181da27e7cbc072ec2fb5bbfe2a9.woff2')
      format('woff2'),
    /* chrome firefox */
      url('https://db.onlinewebfonts.com/t/961a181da27e7cbc072ec2fb5bbfe2a9.woff')
      format('woff'),
    /* chrome firefox */
      url('https://db.onlinewebfonts.com/t/961a181da27e7cbc072ec2fb5bbfe2a9.ttf')
      format('truetype'),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
      url('https://db.onlinewebfonts.com/t/961a181da27e7cbc072ec2fb5bbfe2a9.svg#Circular Std Bold')
      format('svg'); /* iOS 4.1- */
  font-display: swap;
}

#__next {
  height: 100vh;
}

.app {
  background: #f2f2f2;
}

body {
  height: 100vh;
  background: #f2f2f2;
}

@tailwind utilities;

.infinite-scroll-component {
  overflow: hidden !important;
}

.sport-schedule-scroll {
  overflow: auto !important;
}

.separator,
.separator > img {
  width: 100%;
}

.contet-margin {
  margin-top: -22vw;
}

@media only screen and (min-width: 1024px) {
  .separator,
  separator > img {
    max-width: 635px;
  }

  .infinite-scroll-component {
    max-width: 635px;
  }

  .page {
    max-width: 1300px;
  }

  .contet-margin {
    margin-top: 20px;
  }
}

/* .swiper-slide {
  background-color: black !important;
} */

.swiper-slide a {
  width: 33%;
  margin-right: 15px;
}

.swiper-slide a div div {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.swiper-slide a div div div:first-child {
  width: 100% !important;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 900px) {
  /* .swiper-slide {
    background-color: black !important;
  } */

  .swiper-slide a {
    width: 33%;
    margin-right: 15px;
  }

  .swiper-slide a div div {
    width: 30%;
    display: flex;
    flex-direction: column;
  }

  .swiper-slide a div div div:first-child {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 650px) {
  /* .swiper-slide {
    background-color: red !important;
  } */

  .swiper-slide a {
    width: 50%;
  }

  .swiper-slide a div div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .swiper-slide a div div div:first-child {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
}
