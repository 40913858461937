.loginForm {
  height: 300px;

  input {
    height: 40px;
  }

  input[type='text'],
  input[type='password'] {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    font-family: lato-medium;
    font-size: 22px;
  }

  label {
    color: gray;
    letter-spacing: -0px;
    font-family: lato-medium;
    font-size: 15px;
  }

  .emailRequired {
    border-bottom: 1px solid red;
  }

  svg {
    color: #cccbc8;
  }

  .loginError {
    font-size: 10px;
    color: red;
  }

  .loginButton {
    background: #414143;
  }

  .eyeIcon {
    padding-top: 14px;
  }

  .keepMeLoggedInWrapper {
    height: 60px;

    .keepMeLoggedIn {
      font-size: 16px;
    }
  }

  .forgotPassword {
    color: #3fa8f4;
    font-size: 16px;
  }
}
