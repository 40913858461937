@import '../../../styles/variables';

.navItem {
  a {
    font-size: 16px;
    border-bottom: 3px solid transparent;
    font-family: lato-semibold;
  }
}

.navItem:hover {
  background: #790d0f;
  a {
    color: #f7e921;
  }
}

.active {
  background: #790d0f;
  a {
    color: #f7e921;
  }
}
