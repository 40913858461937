.background {
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;

  .signupModal {
    max-width: 540px;

    .active {
      font-family: lato-medium;
    }

    .disabled {
      background: #e6e6e6;
      color: #999999;
    }

    .closeModal {
      position: relative;
      right: 0;
      left: 100%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    .logo {
      width: 90%;
    }

    .body {
      height: 420px;

      .cardsHeader {
        height: 60px;

        .cardName {
          font-family: lat-heavy;
          font-size: 26px;
        }
      }

      .form {
        padding: 30px;
      }
    }

    .closeModalWrapper {
      width: 5%;
    }
  }
}
